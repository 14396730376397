<template>
	<form class="form form--client client" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Client Name" v-model="fields.name" :required="true" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="clientStatus" placeholder="Select Status..." :required="true" />
			</div>

			<h2 class="form-section__title">Contact Information</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Contact Name" v-model="fields.contactName" :required="true" />
				<text-field class="form__field" label="Contact Email" type="email" v-model="fields.contactEmail" :required="true" />
				<text-field class="form__field" label="Contact Phone" type="tel" v-model="fields.contactPhone" :required="true" />
			</div>

			<template v-if="isAdmin">
				<h2 class="form-section__title">EMR Provider</h2>
				<div class="form-row">
					<select-field class="form__field" label="EMR Provider" :options="emrOptions" v-model="selectedProvider" />
					<template v-for="(field, key) in emrFields[selectedProvider]">
						<text-field class="form__field" :label="field" :key="key" v-model="fields.provider.credentials[field]" />
					</template>
				</div>
				<!-- <div class="form-row">
					<text-area class="form__field" label="" v-model="fields.provider" :required="false" :rows="10" :code="true" @input="providerChange" />
				</div> -->
			</template>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button--primary mdc-button--unelevated form__action" type="submit" :disabled="isLoading">
					{{ fields.clientId ? 'Update' : 'Create' }} Client
				</mdc-button>

				<mdc-button class="mdc-button--error form__action" type="button" :disabled="isLoading" @click="remove(fields)" v-if="fields.clientId && fields.status != 'removed'">
					Remove Client
				</mdc-button>
			</div>
		</div>
	</form>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import MdcButton from '@/components/MdcButton'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import { ClientStatus, UserLevel, } from '@/lib/enums'

export default {
	name: 'FormClient',
	components: {
		Checkbox,
		LoadingSpinner,
		MdcButton,
		SelectField,
		TextArea,
		TextField,
	},
	props: {
		client: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			status: 'active',
			locations: [],
		},
		fields: {
			provider: {},
		},
		emrOptions: [
			{
				label: '(none)',
				value: '',
			},
			{
				label: 'Athena',
				value: 'athena',
			},
			{
				label: 'Dr. Chrono',
				value: 'drchrono',
			},
			{
				label: 'Modernizing Medicine',
				value: 'modmed',
			},
		],
		selectedProvider: null,
		emrFields: {
			athena: [
				'baseUrl',
				'client_id',
				'client_secret',
				'practiceId',
			],
			drchrono: [
				'baseUrl',
				'client_id',
				'client_secret',
			],
			modmed: [
				'client_id',
				'client_secret',
				'context_id',
				'firm_url',
				'password',
				'username',
			],
		}
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		},
		clientStatus() {
			return ClientStatus.toDropdown()
		},
		me() {
			return this.$store.getters['user/me']
		},
		isAdmin() {
			return this.me.permissions == UserLevel.Admin
		},
		providerKey: {
			get() {
				return this.client.provider?.key
			},
			set(val) {
				this.selectedProvider = val
				return val
				// this.fields.provider.key = val
			},
		}
	},
	methods: {
		async submitForm(fields) {
			if (fields.clientId) {
				try {
					const { clientId } = fields
					await this.$store.dispatch('client/update', { clientId, data: fields, } )
					this.$notice(`Client updated.`)
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
					console.error(error)
				}
			} else {
				try {
					const res = await this.$store.dispatch('client/create', fields)
					this.$notice(`Client created.`)
					this.$router.push({ name: 'client', params: { clientId: res.clientId, }})
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
				}
			}
		},
		addLocation() {
			this.fields.locations.push({
				name: ``,
				address: ``,
				clientId: this.$route.params.clientId,
			})
		},
		providerChange(val) {
			// try {
			// 	this.fields.provider = JSON.stringify(JSON.parse(val), null, 4)
			// } catch (error) {

			// }
		},
	},
	watch: {
		client: {
			immediate: true,
			handler: function(props) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...props,
				}

				this.selectedProvider = props.provider?.key
			},
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/icon-button/styles';


</style>
