<template>
	<div class="modal modal--client modal-client">
		<div class="modal-client__form">
			<form-client :client="client" />
			<div class="form__row">
				<h3 class="form__title">Locations</h3>
				<list-locations :locations="locations" :no-client="true" />
			</div>
		</div>
		<div class="modal-client__stats">
			<dl>
				<dt>Date Created</dt>
				<dd>{{ client.dateCreated | date('PPp') }}</dd>
				<dt>Created By</dt>
				<dd>{{ client.createdByName }}</dd>
				<dt>Date Modified</dt>
				<dd>{{ client.dateModified | date('PPp') }}</dd>
				<dt>Modified By</dt>
				<dd>{{ client.modifiedByName }}</dd>
			</dl>
		</div>
	</div>
</template>

<script>
import FormClient from '@/components/FormClient'
import ListLocations from '@/components/ListLocations'

export default {
	name: 'ModalClient',
	components: {
		FormClient,
		ListLocations,
	},
	mounted() {
		if (this.$route.params.clientId) {
			this.$store.dispatch('client/get', this.$route.params.clientId)
		}
	},
	destroyed() {
		this.$store.dispatch('client/unset')
	},
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		client() {
			return this.$store.getters['client/client']
		},
		locations() {
			let locations = []
			for (const locationId in this.client.locations || {}) {
				locations.push({
					...this.client.locations[locationId],
					locationId,
					client: this.client,
				})
			}

			return locations
		},
	},
}
</script>

<style scoped lang="scss">
.modal-client {
	display: grid;
	grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	min-height: 50vh;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
